import Handlebars from './handlebars-v1.3.0.min'

$(document).ready(function () {

    // on check of 'edit all events' in user catering settings, automatically check 'view all events'
    $('#perm_catering_edit_all_events').on('change', function() {
        if(this.checked) {
            $('#perm_catering_view_all_events').prop('checked', true);
            $('#perm_catering_view').prop('checked', true);
            $('#perm_catering_view_all_event_details').prop('checked', true);
        }
    });

    $('#perm_catering_view_all_events').on('change', function() {
        if(this.checked) {
            $('#perm_catering_view').prop('checked', true);
        }
    });

    // tooltips 
    $('body').on('mouseover load', '.help-icon[title], .allergen .icons, .additive .icons, .restaurants-colors .restaurant, .list-item-menu li > *:not(.btn-action), .ingredients .na-bg', function () {
        var $this = $(this);
        if (!$this.data('has-tooltip')) {
            var container = $this.attr('data-container');
            if (container) {
                container = $(container)
            } else {
                container = $this.attr('data-tooltip-parent-container');
                if (container) {
                    container = $this.closest(container);
                } else {
                    container = 'body';
                }
            }

            $this.tooltip({
                trigger: 'hover focus',
                placement: 'top',
                container: container
            }).click(function () {
                $(this).focus();
            });
            $this.tooltip('show').data('has-tooltip', 1)
        }
    });

    function detectBrowser() {
        let userAgent = navigator.userAgent;
        let browserName;

        if (userAgent.match(/chrome|chromium|crios/i)) {
            browserName = "chrome";
        } else if (userAgent.match(/firefox|fxios/i)) {
            browserName = "firefox";
        } else if (userAgent.match(/safari/i)) {
            browserName = "safari";
        } else if (userAgent.match(/opr\//i)) {
            browserName = "opera";
        } else if (userAgent.match(/edg/i)) {
            browserName = "edge";
        } else {
            browserName = "no-detection";
        }

        return browserName;
    }

    // print button
    $('.btn-print').click(function () {
        $('body').addClass('browser-print');
        let browserName = detectBrowser();
        if (browserName == "safari") {
            document.execCommand('print', false, null);
        } else {
            window.print();
        }
        return false;
    });
    $('.btn-print.auto-print').click();

    // create some additional elements for left-menu in landscape mode
    (function () {
        $('#left-menu .navbar-toggle.offcanvas').click(function () {
            var cont = $(this).closest('.container').toggleClass('offcanvas-active');
            if (cont.hasClass('offcanvas-active')) {
                // $('#left-menu .main-menu .navbar-nav').height($(window).height()-70);
            }
        });
        var left_nav_brand = $('#left-menu .navbar-brand');
        var left_nav_brand_offcanvas = left_nav_brand.clone(false).addClass('offcanvas');
        left_nav_brand_offcanvas.find('img').remove();
        left_nav_brand.parents('.navbar-header').first().prepend(left_nav_brand_offcanvas);

        $('.main-container .offcanvas-overlay').click(function () {
            $('#left-menu .navbar-toggle.offcanvas').click()
        });
    })();


    // recipe category selector
    $('body').on('click', '.recipe-category-selector li a', function (e) {
        var a = $(this);
        $.ajax({
            url: a.attr('href'),
            beforeSend: function () {
                a.addClass('loading');
                $('[name=close-bulk-sm-menu]').trigger('force-close');
            },
            success: function (data) {
                a.removeClass('loading');

                var recipes_list = $('.recipes-list').removeClass('scroll-loaded');
                if (data.recipes) {
                    recipes_list.html(data.recipes);
                }
                if (data.info_text) {
                    $('.recipes-list-info-text').text(data.info_text);
                }
                if (data.cats) {
                    $('.category-selector-wrapper').html(data.cats);
                }
                if (data.url) {
                    recipes_list.attr('data-scroll-load-url', data.url).removeClass('scroll-loaded');
                } else {
                    recipes_list.removeAttr('data-scroll-load-url').addClass('scroll-loaded');
                }
                a.trigger('clicked');

                //if (!$('.recipe-category-selector li a').first().trigger('fake-click').length) {
                init_scroll_load();
                //}
            },
            complete: function () {
                a.removeClass('loading');
            }
        });
        return false;
    });
    //$('.recipes-list').find('.recipe:visible').first().addClass('first-visible');
    //$('.recipe-category-selector li a').first().trigger('fake-click');


    // recipe bulk actions
    $('.recipes-list').on('click clicked', '.recipe-select', function (e) {
        var $this = $(this);
        $this.parents('.recipe').toggleClass('recipe-selected', $this.is(':checked'));

        var body = $('body');
        if (!body.data('marginBottom')) {
            body.data('marginBottom', body.css('margin-bottom'));
        }

        var bulks = $('.recipes-bulk-wrapper');
        var selects = $('.recipe-select:checked');
        var selected = selects.length;

        // select first visible option if currently selected does not exist
        if (selected && e.type != 'clicked') {
            var toggle_val = bulks.find('.recipe-bulk-action-selector .dropdown-toggle').attr('data-quantity-unit');

            if (!bulks.find('.recipe-bulk-action-selector .dropdown-menu li a[data-value=' + toggle_val + ']').length) {
                bulks.find('.recipe-bulk-action-selector .dropdown-menu li:first-child a').trigger('click');
            }
        }

        if (!selected) {
            bulks.removeClass('visible');
            body.get(0).style.setProperty('margin-bottom', body.data('marginBottom'), 'important');
            return;
        }
        if (selected == 1) {
            bulks.find('single').show();
            bulks.find('plural').hide();
        } else {
            bulks.find('single').hide();
            bulks.find('plural').show();
        }
        bulks.addClass('visible');
        bulks.find('recipes').text(selected);
        body.get(0).style.setProperty('margin-bottom', (bulks.height() + 20) + 'px', 'important');

        // disable unarchive if there are no archived selected
        bulks.find('.recipe-bulk-action-selector .dropdown-menu li a[data-value=unarchive]').parent('li').toggleClass('disabled',
            !selects.parents('.recipe.recipe-archived').length
        );
        // disable archive if there are no active selected
        bulks.find('.recipe-bulk-action-selector .dropdown-menu li a[data-value=archive]').parent('li').toggleClass('disabled',
            !selects.parents('.recipe.recipe-active').length
        );

    }).find('.recipe-select:checked').trigger('clicked');

    // check for empty values
    (function () {
        var no_quantity = true;
        if ($('.recipe-view .ing-quantity').each(function () {
            if ($.trim($(this).text()).length) {
                no_quantity = false;
                return false;
            }
        }).length && no_quantity) {
            $('.recipe-view .ingredients').addClass('no-quantity');
        }
        var no_costs = true;
        if ($('.recipe-view .ingredient-cost-div').each(function () {
            if ($.trim($(this).text()).length > 1) {
                no_costs = false;
                return false;
            }
        }).length && no_costs) {
            $('.recipe-view .ingredients').addClass('no-costs');
        }
    })();

    // additives enumber popover
    (function () {
        $('body').on('click', '.enumber[title]', function () {
            var enumber = $(this);
            // create popover if it does not exist
            if (!enumber.next('.popover').length) {
                enumber.popover({
                    trigger: 'focus',
                    placement: 'top'
                    // show popover
                }).popover('show');
            }
        })
    })();
    // recipe print options
    $('.recipe-view-print-options :checkbox').on('click load', function () {
        var $this = $(this), name = $this.attr('name'),
            checked = $this.is(':checked'), view = $('.recipe-view')

        if (name.match(/^(title|photo|description|categories|price|comments)$/)) {
            view.find('.recipe-' + name).toggleClass('hidden-print', !checked)
        } else
            if (name.match(/^(ingredients|allergens|nutritions|additives|weight)$/)) {
                view.find('.' + name).toggleClass('hidden-print', !checked)
            } else
                if (name == 'ingredients-allergens') {
                    view.find('.ingredients .ing-allergens').toggleClass('hidden-print', !checked)
                } else
                    if (name == 'photo-large') {
                        view.toggleClass('photo-large', checked)
                    } else
                        if (name == 'cmcalc-master-wrap') {
                            view.find('.cmcalc-master-wrap').toggleClass('hidden-print', !checked)
                        }
    }).trigger('load');

    // checkbox dependencies
    $('body').on('click fake-click', 'input[type=checkbox]', function (e) {
        var $this = $(this), deps = $this.attr('data-deps'), reverse = $this.attr('data-deps-reverse') == 'true';
        if (deps) {
            var checked = $this.is(':checked');
            deps = $(deps);
            if ((checked && !reverse) || (!checked && reverse)) {
                deps.removeAttr('disabled').parents('.checkbox, .conf-parent, .form-group').removeClass('disabled')
            } else {
                deps.attr('disabled', 'disabled').parents('.checkbox, .conf-parent, .form-group').addClass('disabled')
            }
        }
    });
    $('[type=checkbox][data-deps]').trigger('fake-click');

    // recipe menu view
    (function () {
        var recipe_menu_view = $('.print-area-view');

        if (recipe_menu_view.length > 0) {
            var fixLastRecipes = function () {
                recipe_menu_view.find('.recipe').removeClass('last-on-page last-in-group');
                recipe_menu_view.find('.recipe + .page-end').prev().addClass('last-on-page');
                recipe_menu_view.find('.recipe-menu-group').each(function () {
                    $(this).nextUntil(':not(.recipe)').last().addClass('last-in-group');
                });
            };
            recipe_menu_view.sortable({
                items: '.recipe:not(.no-dnd), .recipe-menu-group',
                axis: 'y',
                containment: 'parent',
                stop: fixLastRecipes
            });
            // menu create conf
            $('.recipe-menu-conf-selector input').on('click clicked', function (e) {
                var $this = $(this),
                    type = $this.attr('name'),
                    show = $this.is(':checked'),
                    smenu = $('.recipe-menu-conf-selector');

                recipe_menu_view.toggleClass('f-' + type, show);
                if (e.type != 'click') {
                    return
                }

                // save print setting
                $.ajax({
                    url: smenu.attr('data-url'),
                    method: 'post',
                    data: { k: type, v: show }
                });
            }).trigger('clicked');
            // pagination
            $('.recipe-print-buttons .pagination a').on('click clicked click-forced', function (e) {
                var $this = $(this), page = $this.attr('data-page');
                var menu = recipe_menu_view, curr_page = parseInt(menu.attr('curr-page')) || 1;
                var page_s = $('.recipe-print-buttons .pagination a:not([data-page=next-page],[data-page=prev-page])')
                var pages = page_s.length;

                if (e.type == 'clicked') {
                    fixLastRecipes();

                    var ends = recipe_menu_view.find('.page-end').length;
                    if (ends + 1 > pages) {
                        var last = page_s.last().parent('li');
                        var last_page = last.find('> a').attr('data-page') * 1;

                        while (last_page < ends) {
                            last_page++;
                            var nlast = last.clone(true);
                            nlast.find('> a').removeClass('active').attr('data-page', last_page).text(last_page);
                            last.after(nlast);
                            last = nlast;
                        }

                        page_s = $('.recipe-print-buttons .pagination a:not([data-page=next-page],[data-page=prev-page])')
                        pages = page_s.length;
                    }
                }

                if (page == 'next-page') {
                    var next = curr_page + 1;
                    page = (next <= pages) ? next : pages;
                } else
                    if (page == 'prev-page') {
                        var prev = curr_page - 1;
                        page = (prev > 0) ? prev : 1;
                    } else
                        if (page < 0 || page > pages) {
                            page = 1;
                        }

                if (e.type != 'clicked' && e.type != 'click-forced' && curr_page == page) {
                    return false;
                }

                if (e.type == 'clicked' && recipe_menu_view.hasClass('view-mode-print')) {
                    return;
                }

                menu.attr('curr-page', page);
                menu.find('.recipe, .recipe-menu-group').hide();
                menu.find('.page-end:eq(' + (page - 1) + ')').prevUntil('.page-end').show();
                page_s.removeClass('active').filter('[data-page=' + page + ']').addClass('active');

                return false;
            }).filter('[data-page=1]').trigger('clicked');

            // add page
            $('.btn-add-page').on('click', function () {
                recipe_menu_view.find('.recipes > *').last().before('<span class="page-end"></span>');
                $('.recipe-print-buttons .pagination a').trigger('clicked');
            });
            // remove page
            $('.btn-remove-page').on('click', function () {
                if (recipe_menu_view.find('.page-end').length < 2) {
                    return;
                }
                var page = recipe_menu_view.attr('curr-page') || 1;
                var end = recipe_menu_view.find('.page-end:eq(' + (page - 1) + ')');
                end.prevUntil('.page-end').remove();
                end.remove();
                $('.recipe-print-buttons .pagination a:not([data-page="next-page"])').last().remove();
                $('.recipe-print-buttons .pagination a[data-page=1]').trigger('click-forced');
            });

            // hover/click (touch) for selected
            recipe_menu_view.on('click mouseover', '.recipe:not(.no-dnd), .recipe-menu-group', function (e) {
                var $this = $(this);
                if (!$this.hasClass('selected')) {
                    recipe_menu_view.find('.selected').removeClass('selected');
                    $this.addClass('selected');
                    //        } else
                    //        if (e.type == 'click') {
                    //            $this.removeClass('selected');
                }
            });
            recipe_menu_view.on('mouseout', function () {
                recipe_menu_view.find('.selected').removeClass('selected');
            });
            // set card format 
            $('[name=card_format]').on('click change', function () {
                var val = $('[name=card_format]').val();
                var f = val.match(/^([0-9\.]+(cm|mm)?)+x([0-9\.]+(cm|mm)?)$/);
                if (f) {
                    recipe_menu_view.removeClass('x1 x2 x3 x4 y3 y4 y5 y6 y55mm x100mm');
                    recipe_menu_view.addClass('x' + f[3]).addClass('y' + f[1])
                }
            }).trigger('change');
            // copy recipe
            $('body').on('click', '.recipe .btn-copy', function () {
                var recipe = $(this).parents('.recipe');
                recipe.after(recipe.clone());
            });
            // fill the page with the same recipe
            $('body').on('click', '.recipe .btn-fill-page', function () {
                var card_format = $('[name=card_format]');
                var format = card_format.val();
                var formats = {};
                card_format.find('option').each(function () {
                    var $this = $(this), per_page = $this.attr('data-per-page');
                    formats[$this.val()] = per_page ? per_page * 1 : 10;
                });

                if (!formats[format]) {
                    return false;
                }

                var page = recipe_menu_view.attr('curr-page') || 1;
                var recipes = recipe_menu_view.find('.recipes .page-end:eq(' + (page - 1) + ')').prevUntil('.page-end');

                var recipe = $(this).parents('.recipe');
                if (recipes.length < formats[format]) {
                    for (var i = -1, len = formats[format] - recipes.length; ++i < len;) {
                        recipe.after(recipe.clone());
                    }
                }

                return false;
            });
            // add class on click
            recipe_menu_view.on('click', '.btn-add-recipe, .btn-add-group', function () {
                var $this = $(this);
                recipe_menu_view.find('.selected-recipe').removeClass('selected-item');
                $this.parents('.recipe, .recipe-menu-group').addClass('selected-item');
            })
            // add recipe button 
            $('body').on('click', '.btn-add-recipe', function () {
                $(this).async();
                return false;
            });
            // add recipe from the list
            $('body').on('click', '.recipe-menu-add-recipe-modal li[data-id]', function () {
                var $this = $(this), id = $this.attr('data-id'), modal = $('.recipe-menu-add-recipe-modal');

                $.ajax({
                    url: modal.find('.modal-body').attr('data-url-selected-recipe'),
                    method: 'post',
                    data: { id: id },
                    success: function (data) {
                        var sel = $('.recipes .selected-item');
                        if (sel.length) {
                            sel.after($(data.html)).removeClass('selected-item');
                        } else {
                            recipe_menu_view.trigger('append-to-page', $(data.html));
                        }
                        fixLastRecipes();
                        modal.modal('hide');
                    }
                });
                return false;
            });
            // remove recipes
            recipe_menu_view.on('click', '.recipe .btn-delete', function () {
                var recipe = $(this).parents('.recipe');
                var question = recipe_menu_view.attr('data-question-remove-recipe');
                modal_confirm(question, function () {
                    recipe.fadeOut(function () {
                        recipe.remove();
                    });
                    fixLastRecipes();
                    $(this).modal('hide');
                })
            });
            // add content to page
            recipe_menu_view.on('append-to-page', function (e, data) {
                var page = recipe_menu_view.attr('curr-page') || 1;
                var end = recipe_menu_view.find('.recipes .page-end:eq(' + (page - 1) + ')');
                var haft = end.prev('.haftungsas');
                if (haft.length) {
                    haft.before(data);
                } else {
                    end.before(data);
                }
                fixLastRecipes();
            });
            // add group
            $('body').on('click', '.btn-add-group', function () {
                var question = recipe_menu_view.attr('data-text-add-group');
                var modal = modal_input(question, function (text) {
                    if (text && text.length > 0) {
                        var group = Handlebars.compile($('#recipe-menu-add-group-tmpl').html())({ title: text });
                        var sel = $('.recipes .selected-item');
                        if (sel.length) {
                            sel.after(group).removeClass('selected-item');
                        } else {
                            recipe_menu_view.trigger('append-to-page', group);
                        }
                        fixLastRecipes();
                    }
                    $(this).modal('hide');
                });
                modal.addClass('menu-add-group-modal');
            });
            // remove group 
            recipe_menu_view.on('click', '.recipe-menu-group .btn-delete', function () {
                var group = $(this).parents('.recipe-menu-group');
                var question = recipe_menu_view.attr('data-question-remove-group');
                modal_confirm(question, function () {
                    group.fadeOut(function () {
                        group.remove();
                        fixLastRecipes();
                    });
                    $(this).modal('hide');
                })
            });
            // rename group
            recipe_menu_view.on('click', '.btn-rename-group', function () {
                var group = $(this).parents('.recipe-menu-group').find('.title').first();
                var question = recipe_menu_view.attr('data-text-rename-group');
                modal_input(question, function (text) {
                    if (text && text.length > 0) {
                        group.text(text);
                    }
                    $(this).modal('hide');
                }, null, group.text());
            });

            var getPpMM = function () {
                var div = document.createElement("div");
                div.style.width = "100cm";
                var body = document.getElementsByTagName("body")[0];
                body.appendChild(div);
                var ppi = document.defaultView.getComputedStyle(div, null).getPropertyValue('width');
                body.removeChild(div);
                var ppi = parseFloat(ppi);
                return (ppi * devicePixelRatio * screen.pixelDepth / 24) / 1000;
            };

            // set correct height for recipe_menu_view
            var formats = {
                a4: {
                    w: 210,
                    h: 297
                },
                a4_slim: {
                    w: 130,
                    h: 297
                },
                a5: {
                    w: 148,
                    h: 210
                },
            }
            var setMenuPageFormat = function (name) {
                var format = formats[name];
                if (format) {
                    var w = recipe_menu_view.width();
                    var h = (w * format.h / format.w);
                    recipe_menu_view.css('max-height', h);
                    for (var f in formats) {
                        recipe_menu_view.removeClass(f)
                    }
                    recipe_menu_view.addClass(name);

                    $('.menu-design-selector .dropdown-menu a[data-value]').each(function () {
                        var val = $(this).attr('data-value');
                        if (val != 'design1') {
                            recipe_menu_view.removeClass($(this).attr('data-value'));
                        }
                    });
                }
            };
            for (var format in formats) {
                if (recipe_menu_view.hasClass(format)) {
                    setMenuPageFormat(format);
                }
            }

            $('.menu-design-selector a').click(function (e) {
                var $this = $(this);
                setMenuPageFormat($this.attr('data-format'));
                var name = $this.attr('data-value');
                recipe_menu_view.removeClass('ath_drinks').addClass(name);
            });

            $('.btn-pdf, .btn-word').click(function () {
                var btn = $(this).button('loading');
                var f_save = $('.menu-save, .catering-card-save');
                var type = (btn.hasClass('btn-pdf') ? 'pdf' : 'word');
                f_save.append('<input type="hidden" name="' + type + '" value="1">');

                var design = $('.menu-design-selector button').attr('data-quantity-unit');
                if (design) {
                    f_save.append('<input type="hidden" name="design" value="' + design + '">');
                }
                f_save.trigger('submit', [function (data) {
                    f_save.find('[name=' + type + ']').remove();
                    document.location.href = data.url;
                }, function () {
                    btn.button('reset');
                }])
                return false;
            });

            /*
                $('.btn-print-all').click(function() {
                    $('body').addClass('browser-print');
                    recipe_menu_view.find('.recipe').show();
                    window.print();
                    return false;
                });
            */
            // save menu
            $('.menu-save, .catering-card-save').on('submit', function (e, success, complete) {
                var elms = [], form = $(this);

                recipe_menu_view.find('.recipes > *').each(function () {
                    var name, value, $this = $(this);

                    if ($this.hasClass('recipe') && !$this.hasClass('haftungsas')) {
                        name = 'recipe';
                        value = $this.attr('data-rid');
                    } else
                        if ($this.hasClass('recipe-menu-group')) {
                            name = 'group';
                            value = $this.find('.title').text();
                        } else
                            if ($this.hasClass('page-end')) {
                                name = 'page-end';
                                value = 1;
                            } else {
                                return;
                            }

                    elms.push({
                        name: name,
                        value: value
                    });
                });

                var days_add = [], days_remove = [];
                var calender = $('.menu-calender .datepicker');
                if (calender.length) {
                    var addDays = calender.data('add-days') || [],
                        removeDays = calender.data('remove-days') || [];

                    days_add.push({
                        name: 'add_days',
                        value: addDays
                    });
                    days_remove.push({
                        name: 'remove_days',
                        value: removeDays
                    });
                }


                if (form.hasClass('catering-card-save')) {
                    form.find('[name=_format]').remove();
                    form.append($('<input type="hidden" name="_format">').val($('[name=card_format]').val()));
                }

                elms = { elms: elms };

                $.ajax({
                    url: form.attr('action'),
                    method: form.attr('method'),
                    data: form.serialize() + '&' + $.param(elms) + '&' + $.param(days_add) + '&' + $.param(days_remove),
                    success: function (data) {
                        if (data.id) {
                            var id = form.find('[name=id]');
                            if (!id.length) {
                                id = $('<input type="hidden" name="id">');
                                form.append(id);
                            }
                            id.val(data.id);
                        }
                        if (calender.length) {
                            var days = calender.data('original-dates') || [];

                            if (addDays.length) {
                                for (var d in addDays) {
                                    var day = addDays[d];
                                    if (!inArray(day, days)) {
                                        days.push(day);
                                    }
                                }
                            }
                            if (removeDays.length) {
                                for (var d in removeDays) {
                                    var day = removeDays[d];
                                    var idx = $.inArray(day, days);
                                    if (idx >= 0) {
                                        days.splice(idx, 1);
                                    }
                                }
                            }
                            calender.data('original-dates', days);
                            calender.data('add-days', []);
                            calender.data('remove-days', []);
                        }
                        if (success) {
                            success.apply(form, [data]);
                        }
                    },
                    complete: complete
                });

                return false;
            });

        }
    })();


    // list filter
    $('body').on('keyup keydown keypress', 'input.list-filter', function () {
        var $this = $(this), val = $.trim($this.val()), list = $($this.attr('data-list'));

        if (!val.length) {
            list.show();
            return;
        }

        var regex = new RegExp(val, 'i');
        list.hide().filter(function () {
            return $(this).text().match(regex)
        }).show()

    });

    // menu list
    (function () {
        var main_item_list = $('.item-list');
        if (main_item_list.length) {
            // delete menu from the list
            $('body').on('click', '.item-list .btn-delete', function (e) {
                e.stopPropagation();
                e.preventDefault();
                e.stopImmediatePropagation();

                var a = $(this);
                $.getJSON(a.attr('href'), function () {
                    a.parents('.item').first().trigger('deleted');
                });
                return false;
            }).on('deleted', '.item', function () {
                $(this).fadeOut(function () {
                    $(this).remove();
                })

                // copy item and add new one right before the original
            }).on('click', '.item-list .btn-copy', function (e) {
                e.stopPropagation();
                e.preventDefault();
                e.stopImmediatePropagation();

                var a = $(this), href = a.attr('href');
                $.getJSON(a.attr('href'), function (data) {
                    var nr = $(data.html);
                    nr.removeClass('invisible-std')
                    a.parents('.item').first().before(nr);
                    nr.blinkMe();
                    main_item_list.find('.item').removeClass('first-visible');
                    main_item_list.find('.item:visible').first().addClass('first-visible');
                });
                return false;

                // stop propagation
            }).on('click', '.item-list .item li > a', function (e) {
                e.stopPropagation();
                e.stopImmediatePropagation();
            });
        }
    })();

    // shared recipes list pagination
    $('body').on('click', '.recipes-example-list-pagination a', function () {
        var a = $(this);
        $.ajax({
            url: a.attr('href'),
            success: function (data) {
                var middle = $('.col-middle').html(data.html);
                $('html, body').animate({
                    scrollTop: middle.offset().top
                }, .8)
            }
        });
        return false;
    });


    // update page on profile save
    $('.profile-restaurant-form').on('submit', function () {
        var form = $(this);

        form.save(function (data) {
            if (data.restaurant && data.restaurant.length > 0) {
                $('.restaurant-name').text(data.restaurant);
            }
        });

        return false;
    });
    // select-all checkboxes
    $('.select-all').click(function () {
        var $this = $(this);
        $this.parents('.select-all-div').find('[type=checkbox]:not(.select-all)').prop('checked', $this.is(':checked')).trigger('change')
    }).on('change', function () {
        var $this = $(this);
        $this.parents('.checkbox').toggleClass('checked', $this.is(':checked'));
    });
    // check select-all checkbox if all sub-checkboxes are selected
    (function () {
        $('.select-all-div').each(function () {
            var div = $(this);
            var chs = div.find('[type=checkbox]:not(.select-all)');
            var select_all = div.find('.select-all').prop('checked', (chs.length == chs.filter(':checked').length)).trigger('change');
            // de/activate select-all if sub-checkbox deactivated
            chs.click(function () {
                select_all.prop('checked', (chs.length == chs.filter(':checked').length)).trigger('change');
            });
        });
    })();

    // search form toggle
    $('body').on('click', '.btn.search-recipe', function () {
        var form = $('form.search');
        form.slideToggle3d(function () {
            form.find('[name=q],[name=qq]').focus();
        });
        return;
    });

    // user ingredient allergens
    $('body').on('click change', '.user-ingredient-form :checkbox', function () {
        var $this = $(this), allergen = $this.attr('data-allergen');
        let ch;

        if ($this.parents('#user-allergens-traces').length > 0) {
            ch = $('#user-allergens input[data-allergen=' + allergen + ']');
        } else {
            ch = $('#user-allergens-traces input[data-allergen=' + allergen + ']');
        }

        if ($this.is(':checked')) {
            ch.attr('disabled', 'disabled').parents('.checkbox').addClass('disabled');
        } else {
            ch.removeAttr('disabled').parents('.checkbox').removeClass('disabled');
        }
    });
    $('.user-ingredient-form :checkbox').trigger('change');

    // mg-switch
    $('.mg-switch').click(function () {
        var mg = $.trim($(this).text()) == 'mg';
        $('[name=use_gram]').val(mg ? 1 : 0);

        $('.mg-switch').each(function () {
            var $this = $(this);
            $this.text(mg ? 'g' : 'mg');
            var prev = $this.prev('input');
            if (prev.length) {
                var val = $.trim(prev.val().replace(/,/, '.'));
                if (val) {
                    val = val * 1;
                    var nval = mg ? val / 1000 : val * 1000;
                    nval = (nval + '').replace(/\./, ',');
                    prev.val(nval);
                }
            }
        });
        $.ajax({
            url: '/settings/user/save/single',
            data: {
                k: 'user_ingredients_use_gram',
                v: mg
            },
            type: 'post'
        });
    });

    // user ingredients: add/remove units
    $('body').on('click fake-click', '.user-ingredient-form .nutritions-enter .units input[type=checkbox]', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();

        var $this = $(this), id = $this.attr('data-id'), checked = $this.is(':checked'),
            group = '.form-group[data-id=' + id + ']';
        let details = $('.units-details');
        let li = $this.parents('li');

        if (!checked) {
            details.find(group).remove();
            if (li.hasClass('active')) {
                details.find('.info').show();
                details.find('.howto').hide();
            }
            li.removeClass('checked active');
            return;
        }

        // add if it does not exist 
        if (!details.find(group).length) {
            var tmpl = $('#user-ingredient-unit-details-tmpl');
            if (!tmpl.length) {
                return
            }
            details.prepend($(Handlebars.compile(tmpl.html())({ label: li.text(), id: id })));
        }

        li.addClass('checked');

        if (e.type != 'fake-click') {
            li.trigger('fake-click');
        }

        details.find(group).find('input').select().focus();
    });
    $('body').on('click fake-click', '.user-ingredient-form .nutritions-enter .units li', function (e) {
        e.stopPropagation();
        e.stopImmediatePropagation();

        var $this = $(this);
        if ($this.hasClass('active')) {
            return false;
        }

        $('.units').find('li.active').removeClass('active');

        var details = $('.units-details'), input = $this.find('input');
        $this.addClass('active');
        details.find('.form-group, .info').hide();
        details.find('.howto').show();
        var value = details.find('.form-group[data-id=' + input.attr('data-id') + ']').show();

        if (e.type != 'fake-click') {
            if (!input.is(':checked')) {
                input.prop('checked', true);
                input.trigger('fake-click');
            } else {
                input.select().focus();
            }
        }
        value.find('input').select().focus();
    });
    // user ingredients: toggle units
    $('body').on('click', '.user-ingredients-hide-toggle-checked', function () {
        $('.nutritions-enter .units').toggleClass('hide-checked');
        $(this).hide().siblings().show();
    });
    if ($('.nutritions-enter .units li.checked').length) {
        $('.user-ingredients-hide-toggle-checked.show-selected-units').click();
    }

    // delete photo
    $('body').on('click', '.add-photo .btn-delete', function () {
        var $this = $(this), img = $this.parents('.add-photo').find('.photo img');
        if (!img.length) {
            return false;
        }

        var url = img.attr('data-url');
        var delete_photo = function () {
            img.parents('.photo').first().fadeOut(function () {
                $(this).remove();
            });
        };

        if ($this.form('[name=id]').length || !url) {
            delete_photo();
        } else
            if (url) {
                $.ajax({
                    url: url,
                    success: delete_photo
                });
            }
        return false;
    });

    // std upload
    $('body').on('click', '.fileupload', function (e) {
        var input = $(this), url = input.attr('data-url'), limit = input.attr('data-limit');
        var bar = input.parents('.fileinput-button'), progress_bar = bar.find('.progress-bar'), percent = progress_bar.find('.percent');

        if (bar.hasClass('uploading')) {
            return false;
        }

        //if (!input.hasClass('fileupload-added')) {
        var dont_update_percentage = (input.attr('data-update-percentage') == 'false');
        input.fileupload({
            url: input.attr('data-url'),
            dataType: 'json',
            sequentialUploads: true,
            done: function (e, data) {
                $(this).trigger('upload-success', [data.jqXHR.responseJSON || null]);
                bar.removeClass('uploading');
                console.log($(this))
            },
            submit: function () {
                bar.removeClass('uploaded').addClass('uploading');
                progress_bar.css('width', '0%');

                if (!dont_update_percentage) {
                    percent.text('');
                }
                $(this).trigger('upload-start')
                return true;
            },
            progress: function (e, data) {
                var progress_pct = parseInt(data.loaded / data.total * 100, 10);
                var progress = progress_pct + '%';
                progress_bar.css('width', progress);
                if (!dont_update_percentage) {
                    percent.text(progress);
                }
                if (progress_pct == 100) {
                    $(this).trigger('upload-done', [data])
                }
            },
            add: function (e, data) {
                data.submit();
            },
            fail: function (e, data, a) {
                $(this).trigger('reset').trigger('upload-error', [data.jqXHR.responseJSON || null]);
            },
            always: function (e, data) {
                var $this = $(this);
                if ($this.attr('data-reset-on-upload') != 'false') {
                    $this.trigger('reset');
                }
            }
        }).prop('disabled', !$.support.fileInput)
            .addClass('fileupload-added')
            .parent().addClass($.support.fileInput ? undefined : 'disabled')
        //}
    }).on('reset', function () {
        $(this).parents('.fileinput-button').removeClass('uploading').addClass('uploaded');
    });

    $('.xls-upload .fileupload').on('upload-error', function () {
        $(this).parents('.row').find('.upload-error').show()
    }).on('upload-start', function () {
        $(this).parents('.row').find('.upload-error').hide()
    }).on('upload-done', function () {
        //var btn = $(this).parents('.fileinput-button[data-text-import-running]');
        //btn.find('.percent').text(btn.attr('data-text-import-running'));
    }).on('upload-success', function () {
    });

    // cmcalc list save
    $('.cmcalc-ingredients-list input').on('change update', function () {
        var $this = $(this), row = $this.parents('.row[data-url]'), loader = $this, form_group = $this.parents('.form-group');
        if ($this.attr('type') == 'hidden') {
            loader = form_group.find('input[type=text]');
        }

        $.ajax({
            url: row.attr('data-url'),
            type: 'post',
            data: row.find('input').serialize(),
            beforeSend: function () {
                loader.addClass('loading')
            },
            complete: function () {
                loader.removeClass('loading')
            },
            success: function () {
                var ok = $('<span class="glyphicon glyphicon-ok form-control-feedback" aria-hidden="true"></span>');
                loader.removeClass('loading').siblings('.form-control-feedback').remove();
                loader.after(ok);
                form_group.removeClass('has-error').addClass('has-success has-feedback');
                $this.get(0).defaultValue = $this.val();

                setTimeout(function () {
                    ok.fadeOut(function () {
                        $(this).remove()
                        form_group.removeClass('has-success has-feedback');
                    });
                }, 2000);

            },
            error: function (e) {
                var not_ok = $('<span class="glyphicon glyphicon-refresh form-control-feedback" aria-hidden="true"></span>');
                loader.removeClass('loading').siblings('.form-control-feedback').remove();
                form_group.removeClass('has-success').addClass('has-error has-feedback');
                loader.after(not_ok);

                not_ok.click(function () {
                    loader.siblings('.form-control-feedback').remove();
                    form_group.removeClass('has-error has-feedback');
                    $this.val($this.get(0).defaultValue);
                });

                if ('responseJSON' in e) {
                    var data = e.responseJSON;
                    if ('f' in data && 'e' in data) {
                        row.find('input[name=' + data.f + ']').showPopover(data.e, 2000);
                    }
                }
            }
        });
        return false;
    });

    // recipe gallery
    (function () {
        // gallery on open
        $('body').on('shown.bs.modal', '.modal.recipe-gallery-modal', function () {
            var modal = $(this);

            // sortable images
            modal.find('.photos').sortable({
                items: '.photo',
                axis: 'y',
                containment: 'parent',
                cursor: 'move',
                handle: '.btn-move, > div',
                update: function (e, ui) {
                    var $this = $(this);

                    $.ajax({
                        url: $this.attr('data-resort-url'),
                        type: 'post',
                        data: $this.find('input.id').serialize(),
                        success: function () {
                            // resort carousel
                            var carousel = $('.carousel-inner');

                            $($this.find('input.id').get().reverse()).each(function () {
                                var id = $(this).val();
                                var item = carousel.find('img[data-id=' + id + ']').closest('.item');
                                carousel.prepend(item);
                            });

                            carousel.closest('.carousel').trigger('re-init');
                        }
                    });
                }
            })

                // photos "limit-reached"
                .on('limit-reached', function () {
                    $(this).siblings('.limit-msg').slideDown();
                    modal.find('.fileinput-button').addClass('disabled');
                })
                // photos "limit-unreached"
                .on('limit-unreached', function () {
                    $(this).siblings('.limit-msg').slideUp();
                    modal.find('.fileinput-button').removeClass('disabled');
                })

            // delete image
            modal.on('click', '.btn-remove[data-href]', function () {
                var btn = $(this);

                btn.async(function () {
                    btn.closest('.photo').slideUp(function () {
                        var photo = btn.closest('.photo');
                        var photos = photo.closest('.photos');

                        $(this).remove();
                        $('.add-photo').trigger('image-removed', [photo]);

                        photos.trigger('limit-unreached');

                        if (!photos.find('.photo').length) {
                            photos.addClass('hidden');
                        }
                    });
                });
            });

            // delete image question modal
            // on "shown", set z-index of gallery modal to 10
            // on "hidden" restore z-index
            $('body').on('shown.bs.modal', '.modal.question-modal', function () {
                modal.addClass('submodal');
            }).on('hidden.bs.modal', '.modal.question-modal', function () {
                modal.removeClass('submodal');
            });

            // edit description
            modal.on('click', '.description', function () {
                $(this).closest('.photo').addClass('edit').find('textarea').focus();
            });
            // close "edit description"
            modal.on('click', '.photo .edit .close', function () {
                $(this).closest('.photo').removeClass('edit');
            });
            // save description
            modal.on('submit', '.photo .edit', function () {
                var $this = $(this), form = $this.closest('form'),
                    desc = form.find('textarea'),
                    description = desc.val();

                var submit = form.find('.btn-submit').button('loading')
                $.ajax({
                    url: form.attr('action'),
                    type: 'post',
                    data: {
                        id: form.closest('.photo').find('input.id').val(),
                        description: description,
                    },
                    success: function () {
                        var photo = $this.closest('.photo');
                        var desc_empty = ($.trim(description).length == 0);

                        var p = photo.find('.description');
                        p.toggleClass('no-description', desc_empty);

                        if (desc_empty) {
                            description = desc.attr('placeholder');
                        }

                        p.text(description);
                        photo.removeClass('edit');
                    },
                    complete: function () {
                        submit.button('reset');
                    }
                });
                return false;
            });

            var input = $(".fileupload-img"),
                url = input.attr('data-url'),
                photos_list = $(input.attr('data-photos-container')),
                limit = input.attr('data-limit'),
                limit_remove_first = input.attr('data-limit-remove-first'),
                prepend_photo = input.attr('data-prepend-photo') == 'true',
                connect_with = input.attr('data-connect-with');

            if (!input.hasClass('fileupload-added')) {
                input.fileupload({
                    url: input.attr('data-url'),
                    dataType: 'json',
                    sequentialUploads: false,
                    limitConcurrentUploads: 3,
                    done: function (e, data) {
                        if (data.result.imgs) {
                            $.each(data.result.imgs, function (index, imgdata) {
                                if (data.context) {
                                    var img = data.context.img.find('img')
                                        .attr('src', imgdata.thumb)
                                        .attr('data-id', imgdata.id)
                                        .attr('data-url', imgdata.url);

                                    img.siblings('[type=hidden].id').val(imgdata.id);

                                    // remove url
                                    if ('remove_url' in imgdata) {
                                        data.context.img.find('.btn-remove').attr('data-href', imgdata.remove_url)
                                    }

                                    data.context.img.removeClass('uploading');

                                    // inform connect_with
                                    if (connect_with) {
                                        $(connect_with).trigger('image-added', [data.context.img]);
                                    }

                                    if (limit) {
                                        var photos = photos_list.find('img');
                                        if (photos.length > limit && limit_remove_first) {
                                            if (prepend_photo) {
                                                photos = $(photos.get().reverse());
                                            }
                                            photos.each(function (i) {
                                                if (i < limit) {
                                                    $(this).parents('.photo').first().remove();
                                                }
                                            });
                                        }
                                    }
                                }
                            });
                        }
                        if (data.result['product_id']) {
                            var editGallery = $('.product-photo.add-photo a.async'),
                                href = editGallery.attr('href');
                            if (!href.match(/\d+$/)) {
                                href += '/' + data.result['product_id'];
                                editGallery.attr('href', href);
                            }

                            $('input[name="product-id"]').val(data.result['product_id']);
                        }
                    },
                    submit: function () {
                        return true
                    },
                    progress: function (e, data) {
                        if (data.context) {
                            var progress = parseInt(data.loaded / data.total * 100, 10) + '%';
                            data.context.bar.css('width', progress).text(progress);
                        }
                    },
                    add: function (e, data) {
                        console.log(data);
                        // check limit to disable upload
                        if (limit && !limit_remove_first) {
                            var photos = photos_list.find('.photo');
                            if (photos.length >= limit) {
                                photos.trigger('limit-reached');
                                return false;
                            }
                        }


                        var temp_name = '';
                        if (data.files && data.files.length > 0 && data.files[0].name) {
                            temp_name = data.files[0].name;
                        }



                        var newimg = $(Handlebars.compile(photos_list.find('script.tmpl').html())({ uploading: 'uploading', temp_name: temp_name }));
                        var newimg_progress_bar = newimg.find('.progress-bar');
                        if (prepend_photo) {
                            photos_list.prepend(newimg);
                        } else {
                            photos_list.append(newimg);
                        }

                        // check again to show msg rightaway
                        if (limit && !limit_remove_first) {
                            photos = photos_list.find('.photo');
                            if (photos.length >= limit) {
                                photos.trigger('limit-reached');
                            }
                        }

                        data.context = {
                            img: newimg.first(),
                            bar: newimg_progress_bar.first()
                        };

                        photos_list.removeClass('hidden');
                        photos_list.slideDown();
                        data.submit();
                    },
                    fail: function (e, data) {
                        if (data.context) {
                            data.context.img.remove();
                            //notification(translations.unknown_upload_error);
                            //notification('upload error')
                            var photos = photos_list.find('.photo');
                            if (!photos.length) {
                                photos.addClass('hidden');
                            } else {
                                photos.removeClass('hidden');
                            }
                        }
                    },
                    always: function (e, data) {
                        // check again to fix it
                        setTimeout(function () {
                            var photos = photos_list.find('.photo');
                            if (limit && !limit_remove_first) {
                                if (photos.length >= limit) {
                                    photos.trigger('limit-reached');
                                } else {
                                    photos.trigger('limit-unreached');
                                }
                            }
                        }, 100);
                    }
                }).prop('disabled', !$.support.fileInput)
                    .addClass('fileupload-added')
                    .parent().addClass($.support.fileInput ? undefined : 'disabled')
            }
        });


        // image added to gallery? add it to carousel
        $('.add-photo').on('image-added', function (e, img) {
            var $this = $(this),
                input = img.find('.img input'),
                img = img.find('.img img'),
                item = $('<div class="item"></div>').append(img.clone()).append(input.clone());
            let carousel = $this.find('.carousel-inner').append(item).closest('.carousel');

            carousel.siblings('.upload-commands').addClass('hidden');

            if (!carousel.find('.item.active').length) {
                carousel.find('.item:first').addClass('active');
            }

            carousel.trigger('re-init');

            // image removed from gallery? remove from carousel
        }).on('image-removed', function (e, img) {
            var img = img.find('.img img'), id = img.attr('data-id');
            var $this = $(this);

            var carousel = $this.find('.carousel');
            carousel.find('img[data-id=' + id + ']').closest('.item').remove();

            if (!carousel.find('.item.active').length) {
                if (!carousel.find('.item:first').addClass('active').length) {
                    carousel.siblings('.upload-commands').removeClass('hidden');
                }
            }

            carousel.trigger('re-init');

            // re-init carousel
        }).find('.carousel').on('re-init', function () {
            var carousel = $(this);
            carousel.find('.status .current').text(carousel.find('.item.active').index() + 1);
            carousel.find('.status .total').text(carousel.find('.item').length);

            // run on slide
        }).on('slid.bs.carousel', function () {
            $(this).trigger('re-init')

            // run on start
        }).trigger('re-init')

            // click anywhere? open gallery
            .find('.carousel-content').on('click', function () {
                var $this = $(this), a = $this.closest('.add-photo').find('.upload-commands');
                var images = $this.find('input[type=hidden]').serialize();
            let href;

                if (a.data('href')) {
                    href = a.data('href');
                } else {
                    href = a.attr('href');
                    a.data('href', href);
                }
                a.attr('href', href + '?' + images);
                a.click();
            });

        // gallery on close
        $('body').on('hide.bs.modal', '.modal.recipe-gallery-modal', function () {
            // disable event on delete modal
            $('body').off('shown.bs.modal', '.modal.question-modal')
                .off('hidden.bs.modal', '.modal.question-modal');
        });

    })();

    // image upload

    $('body').on('click', '.fileupload-img', function (e) {
        // var input = $(this),
        //     url = input.attr('data-url'),
        //     photos_list = $(input.attr('data-photos-container')),
        //     limit = input.attr('data-limit'),
        //     limit_remove_first = input.attr('data-limit-remove-first'),
        //     prepend_photo = input.attr('data-prepend-photo') == 'true',
        //     connect_with = input.attr('data-connect-with');

        // if (!input.hasClass('fileupload-added')) {
        //     input.fileupload({
        //         url: input.attr('data-url'),
        //         dataType: 'json',
        //         sequentialUploads: false,
        //         limitConcurrentUploads: 3,
        //         done: function (e, data) {
        //             if (data.result.imgs) {
        //                 $.each(data.result.imgs, function (index, imgdata) {
        //                     if (data.context) {
        //                         var img = data.context.img.find('img')
        //                             .attr('src', imgdata.thumb)
        //                             .attr('data-id', imgdata.id)
        //                             .attr('data-url', imgdata.url);

        //                         img.siblings('[type=hidden].id').val(imgdata.id);

        //                         // remove url
        //                         if ('remove_url' in imgdata) {
        //                             data.context.img.find('.btn-remove').attr('data-href', imgdata.remove_url)
        //                         }

        //                         data.context.img.removeClass('uploading');

        //                         // inform connect_with
        //                         if (connect_with) {
        //                             $(connect_with).trigger('image-added', [data.context.img]);
        //                         }

        //                         if (limit) {
        //                             var photos = photos_list.find('img');
        //                             if (photos.length > limit && limit_remove_first) {
        //                                 if (prepend_photo) {
        //                                     photos = $(photos.get().reverse());
        //                                 }
        //                                 photos.each(function (i) {
        //                                     if (i < limit) {
        //                                         $(this).parents('.photo').first().remove();
        //                                     }
        //                                 });
        //                             }
        //                         }
        //                     }
        //                 });
        //             }
        //             if (data.result['product_id']) {
        //                 var editGallery = $('.product-photo.add-photo a.async'),
        //                     href = editGallery.attr('href');
        //                 if (!href.match(/\d+$/)) {
        //                     href += '/' + data.result['product_id'];
        //                     editGallery.attr('href', href);
        //                 }

        //                 $('input[name="product-id"]').val(data.result['product_id']);
        //             }
        //         },
        //         submit: function () {
        //             return true
        //         },
        //         progress: function (e, data) {
        //             if (data.context) {
        //                 var progress = parseInt(data.loaded / data.total * 100, 10) + '%';
        //                 data.context.bar.css('width', progress).text(progress);
        //             }
        //         },
        //         add: function (e, data) {
        //             // check limit to disable upload
        //             if (limit && !limit_remove_first) {
        //                 var photos = photos_list.find('.photo');
        //                 if (photos.length >= limit) {
        //                     photos.trigger('limit-reached');
        //                     return false;
        //                 }
        //             }

        //             var temp_name = '';
        //             if (data.files && data.files.length > 0 && data.files[0].name) {
        //                 temp_name = data.files[0].name;
        //             }

        //             var newimg = $(Handlebars.compile(photos_list.find('script.tmpl').html())({ uploading: 'uploading', temp_name: temp_name }));
        //             var newimg_progress_bar = newimg.find('.progress-bar');
        //             if (prepend_photo) {
        //                 photos_list.prepend(newimg);
        //             } else {
        //                 photos_list.append(newimg);
        //             }

        //             // check again to show msg rightaway
        //             if (limit && !limit_remove_first) {
        //                 photos = photos_list.find('.photo');
        //                 if (photos.length >= limit) {
        //                     photos.trigger('limit-reached');
        //                 }
        //             }

        //             data.context = {
        //                 img: newimg.first(),
        //                 bar: newimg_progress_bar.first()
        //             };

        //             photos_list.removeClass('hidden');
        //             photos_list.slideDown();
        //             data.submit();
        //         },
        //         fail: function (e, data) {
        //             if (data.context) {
        //                 data.context.img.remove();
        //                 //notification(translations.unknown_upload_error);
        //                 //notification('upload error')
        //                 var photos = photos_list.find('.photo');
        //                 if (!photos.length) {
        //                     photos.addClass('hidden');
        //                 } else {
        //                     photos.removeClass('hidden');
        //                 }
        //             }
        //         },
        //         always: function (e, data) {
        //             // check again to fix it
        //             setTimeout(function () {
        //                 var photos = photos_list.find('.photo');
        //                 if (limit && !limit_remove_first) {
        //                     if (photos.length >= limit) {
        //                         photos.trigger('limit-reached');
        //                     } else {
        //                         photos.trigger('limit-unreached');
        //                     }
        //                 }
        //             }, 100);
        //         }
        //     }).prop('disabled', !$.support.fileInput)
        //         .addClass('fileupload-added')
        //         .parent().addClass($.support.fileInput ? undefined : 'disabled')
        // }
    });


    // promotion code show
    $('.payment form .promocode-show-btn').click(function () {
        var psa = $(this).parents('.promocode-show-area').first().hide();
        psa.nextAll().show();
        psa.nextAll('.error-area').hide();
        return false;
    });
    // promotion code enter
    $('.payment form button[name=promocode-check]').click(function () {
        var $this = $(this), form = $this.form(),
            one_time = form.find('[name=one_time_payment]').is(':checked');

        $.ajax({
            url: $this.attr('data-url'),
            method: 'post',
            data: {
                promocode: form.find('.promocode-area [name=promocode]').val(),
                package: form.find('[name=payment_package]').val(),
                months: form.find('[name=months]').val(),
                one_time_payment: one_time ? 1 : 0,
                payment_method: form.find('[name=payment_method]').val()
            },
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                if (data.code && data.sum && data.amo) {
                    var ar = form.find('.promocode-value');
                    if (data.val != null) {
                        ar.find('value').text(data.val);
                    }
                    ar.slideDown();
                    form.find('.promocode-enter').slideUp();
                    form.find('.package-sum value').text(data.sum);
                    form.find('.package-sum [name=promocode_use]').val(data.code);
                    form.find('.subtotal').slideDown().find('value').text(data.amo);
                    form.find('.st-sum value').text(data.st_sum);
                    var pm = form.find('.st-sum .per-month');
                    one_time ? pm.hide() : pm.show();

                    if (data.valid_from_until) {
                        form.find('.valid-from-until').text(data.valid_from_until);
                    }
                    var valid_months = form.find('.valid-months');
                    var old_val = valid_months.text();
                    if (data.valid_months) {
                        valid_months.text(data.valid_months);
                    }
                    valid_months.toggleClass('changed', (old_val != valid_months.text()));
                }
            }
        });
        return false;
    });
    // promotion code cancel
    $('.payment form button[name=promocode-cancel]').click(function () {
        var $this = $(this), form = $this.form(),
            one_time = form.find('[name=one_time_payment]').is(':checked');

        $.ajax({
            url: $this.attr('data-url'),
            method: 'post',
            data: {
                package: form.find('[name=payment_package]').val(),
                months: form.find('[name=months]').val(),
                one_time_payment: one_time ? 1 : 0,
                payment_method: form.find('[name=payment_method]').val()
            },
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                if (data.sum) {
                    form.find('.promocode-enter').slideDown();
                    form.find('.promocode-value').slideUp();
                    form.find('.package-sum [name=promocode_use]').val('');
                    form.find('.package-sum value').text(data.sum);
                    form.find('.st-sum value').text(data.st_sum);
                    var pm = form.find('.st-sum .per-month');
                    one_time ? pm.hide() : pm.show();

                    form.find('.subtotal').slideUp();
                    if (data.valid_from_until) {
                        form.find('.valid-from-until').text(data.valid_from_until);
                    }
                    if (data.valid_months) {
                        form.find('.valid-months').text(data.valid_months);
                    }
                }
            }
        });
        return false;
    });
    $('.payment form [name=one_time_payment]').on('click', function (e) {
        var that = this, $this = $(this), form = $this.form(), one_time = $this.is(':checked') ? 1 : 0

        $.ajax({
            url: $this.attr('data-url'),
            method: 'post',
            data: {
                package: form.find('[name=payment_package]').val(),
                months: form.find('[name=months]').val(),
                promocode: form.find('[name=promocode_use]').val(),
                one_time_payment: one_time ? 1 : 0,
                payment_method: form.find('[name=payment_method]').val()
            },
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                form = $('.payment form:has([name=one_time_payment])');

                if (data.val) {
                    var ar = form.find('.promocode-value');
                    if (data.val != null) {
                        ar.find('value').text(data.val);
                    }
                    ar.slideDown();
                    form.find('.promocode-enter').slideUp();
                    form.find('.subtotal').slideDown().find('value').text(data.amo);
                }
                if (data.code) {
                    form.find('.package-sum [name=promocode_use]').val(data.code);
                }

                form.find('.package-sum value').text(data.sum);
                form.find('.payment-text').text(data.payment_text);
                form.find('.st-sum value').text(data.st_sum);
                var pm = form.find('.st-sum .per-month');
                one_time ? pm.hide() : pm.show();

                if (data.valid_from_until) {
                    form.find('.valid-from-until').text(data.valid_from_until);
                }
                var valid_months = form.find('.valid-months');
                var old_val = valid_months.text();
                if (data.valid_months) {
                    valid_months.text(data.valid_months);
                }
                valid_months.toggleClass('changed', (old_val != valid_months.text()));

                $('.payment.nav-tabs a.otp').toggleClass('disabled', !one_time);
                form.find('[name=one_time_payment]').not(that).prop('checked', one_time);
            }
        });
    });
    //$('.payment-calc [name=one_time_payment], .payment-calc[name=datenaktualisierung]').click(function() {
    $('.payment-calc [type=radio]').click(function () {
        var form = $('.payment-calc');
        m = $('.plans .plan.selected-package').attr('data-plan'),
            one_time_payment = $('#one-time-payment').is(':checked') ? 1 : 0;

        $.ajax({
            url: form.attr('data-url'),
            type: 'post',
            data: {
                package: m,
                months: form.find('[name=months]').val(),
                promocode: form.find('[name=promocode]').val(),
                one_time_payment: one_time_payment,
                payment_method: 'cc',
                extra: {
                    datarefresh: form.find('#datarefresh-on').is(':checked') ? 1 : 0
                }
            },
            beforeSend: function () {
                $('html').addClass('async-loading');
            },
            success: function (data) {
                form.find('.monthly-price value').text(data.oamount);
                form.find('.period-sum value').text(data.oamount_sum);

                if (data.oamount_monthly) {
                    form.find('.monthly-price').addClass('month');
                } else {
                    form.find('.monthly-price').removeClass('month');
                }

                if (data.metro_prt) {
                    form.find('.metro-prt value').text(data.metro_prt);
                    form.find('.metro-sum value').text(data.metro_sum);
                }

                var monthly_sum = form.find('.monthly-sum');
                if (one_time_payment) {
                    monthly_sum.slideUp();
                } else {
                    monthly_sum.slideDown().find('value').text(data.amo);
                }

                form.find('.skonto-text value').text(data.skonto_prt);
                form.find('.skonto-sum value').text(data.skonto_sum);

                form.find('.total-saving value').text(data.saving);

                form.find('.vat value').text(data.vat);
                form.find('.vat-sum value').text(data.vat_sum);

                form.find('.total-sum value').text(data.sum);
                form.find('.metro-non-sum value').text(data.metro_non_sum);

                if (data.extra) {
                    for (var x in data.extra) {
                        form.find('.extra-' + x + ' value').text(data.extra[x]);
                    }
                }
            }
        })
    });
    $('.payment-calc [name=months][type=hidden]').change(function () {
        $('.payment-calc [name=one_time_payment]:checked').click();
    });

    // account packages calc
    $('.plans .plan').click(function () {
        var $this = $(this), wrap = $this.closest('.plans'), plans = wrap.find('.plan'),
            form = $('.payment-calc'),
            m = $this.attr('data-plan');

        wrap.removeClass('selected basic standard premium premium-de free-de premium-de-month premium-de-year');
        if ($this.hasClass('selected-package')) {
            plans.removeClass('selected-package');
        } else
            if ($this.hasClass('not-selectable')) {
                plans.removeClass('selected-package');

            } else {
                form.find('#one-time-payment').prop('checked', true);

                var months = $this.attr('data-months-default') || 12;
                form.find('.months-selector .dropdown-menu a[data-value=' + months + ']').trigger('fake-click');

                wrap.addClass('selected').addClass(m).attr('data-months', months);

                plans.removeClass('selected-package');
                $this.addClass('selected-package');
                form.find('[name=one_time_payment]:checked').click();
            }
    });

    $('.go-to-payment').click(function () {
        var $this = $(this), form = $('.payment-calc')
        m = $('.plans .plan.selected-package').attr('data-plan');

        $.ajax({
            url: $this.attr('data-url'),
            type: 'post',
            data: {
                package: m,
                months: form.find('[name=months]').val(),
                promocode: form.find('[name=promocode]').val(),
                one_time_payment: $('#one-time-payment').is(':checked') ? 1 : 0,
                payment_method: 'cc',
                extra: {
                    datarefresh: form.find('#datarefresh-on').is(':checked') ? 1 : 0
                }
            },
            beforeSend: function () {
                $('html').addClass('async-loading');
            }
        });
    });

    // submit payment
    $('.account-payment-form-cc').on('submit', function () {
        var form = $(this);
        $('html').addClass('async-loading');

        form.save(function (data) {
            if (data.html) {
                var p = form.parent();
                form.after(data.html);
                form.remove();
                setTimeout(function () {
                    p.find('form.threedredirect-form').submit();
                }, 1000);
            }
        });
        return false;
    });

    // show metro field if austria selected
    $('.profile-company-form [name=country]').change(function () {
        var $this = $(this), wrap = $this.form('.metro-wrap'), val = $this.val();
        if (val == 'AT' || val == 'DE') {
            // exclude AT from signup page
            if (val == 'AT' && $this.closest('.signup-form').length) {
                wrap.slideUp();
            } else {
                wrap.slideDown().removeClass('at de').addClass(val.toLowerCase());
            }
        } else {
            wrap.slideUp();
        }
    }).change();


    // auto focus on start
    (function () {
        // set cursor on end of the string
        setTimeout(function () {
            var input = $('.focus-on-start').first();

            // is it an auto-complete input field? wait little bit until
            // other scripts finish their work and focus after that
            var autocomplete_form = input.closest('form[data-url-autocomplete]').length;

            setTimeout(function () {
                if (autocomplete_form) {
                    input = input.siblings('input:not([disabled])');
                }
                // doublefocus because of typeahead.js issues
                input.focus().focus().filter('[type=text], [type=password], [type=email], [type=number], [type=url]');
                if (input.length) {
                    input.blur().blur().focus().focus().val(input.val());
                }
            }, autocomplete_form ? 50 : 0);
        }, 0);
    })();

    // auto click on start
    $('.btn.click-on-start').first().click();

    // create "random" string
    $('[name=random-string][data-target]').click(function () {
        $($(this).attr('data-target')).val(new Array(10).join().replace(/(.|$)/g, function () { return ((Math.random() * 36) | 0).toString(36)[Math.random() < .5 ? "toString" : "toUpperCase"](); }));
    });

    // mark required fields
    $('.mark-required').on('mark-required', function () {
        var show_feedback = $(this).hasClass('show-feedback');

        $(this).find('.form-group.required').find('input, select, textarea').each(function () {
            var $this = $(this), val = $this.val(), len = $.trim(val).length;
            $this.parents('.form-group.required').toggleClass('has-error', (!val || !len));

            if (show_feedback) {
                $this.toggleClass('has-feedback', (!val || !len));
            }
        })
    }).trigger('mark-required');

    (function () {
        var gmap = $('#gmap:visible');

        if (gmap.length) {
            google_map_init = function () {
                var map_canvas = gmap.get(0);
                var position = {
                    coords: {
                        latitude: 48.2087079999999,
                        longitude: 16.372302999999988
                    }
                };

                var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                var map_options = {
                    center: latlng,
                    zoom: 13,
                    disableDefaultUI: true,
                    mapTypeId: google.maps.MapTypeId.ROADMAP
                }
                var map = new google.maps.Map(map_canvas, map_options);
                var marker = new google.maps.Marker({
                    //position: latlng, 
                    map: map,
                    title: $('#restaurant').val()
                });

                var keydown_timeout;
                var address = $('#address');
                address.on('change select update', function (e) {
                    var that = $(this), val = that.val();
                    var geocoder = new google.maps.Geocoder();

                    if (val == 0 || $.trim(val).length == 0) {
                        return
                    }

                    geocoder.geocode({ 'address': val }, function (results, status) {
                        if (status == google.maps.GeocoderStatus.OK) {
                            map.setCenter(results[0].geometry.location);
                            map.fitBounds(results[0].geometry.viewport);
                            if (that.hasClass('google-map-country')) {
                                region.val('');
                                ac_region.setComponentRestrictions({ country: val });
                            }
                            marker.setPosition(results[0].geometry.location);
                        }
                    });
                }).on('keydown', function () {
                    clearTimeout(keydown_timeout);
                    keydown_timeout = setTimeout(function () {
                        address.triggerHandler('update');
                    }, 400);
                }).triggerHandler('update');
            };

            $.getScript('https://maps.googleapis.com/maps/api/js?sensor=TRUE&callback=google_map_init&libraries=places&language=' + ($('html').attr('lang') || 'en'));
        }
    })();

    // color selector
    (function () {
        var cs = $('.colorselector');
        if (cs.length) {
            cs.colorselector();
        }
    })();

    // restaurant selector
    $('.recipe-restaurant-selector a, .recipe-db-selector a').click(function (e) {
        var a = $(this);
        return;

        $.ajax({
            url: a.attr('href'),
            beforeSend: function () {
                a.addClass('loading');
            },
            success: function (data) {
                a.removeClass('loading');

                var recipes_list = $('.recipes-list').removeClass('scroll-loaded');
                if (data.recipes) {
                    recipes_list.html(data.recipes);
                }
                if (data.info_text) {
                    $('.recipes-list-info-text').text(data.info_text);
                }
                if (data.cats) {
                    $('.category-selector-wrapper').html(data.cats);
                }
                if (data.url) {
                    recipes_list.attr('data-scroll-load-url', data.url).removeClass('scroll-loaded');
                } else {
                    recipes_list.removeAttr('data-scroll-load-url').addClass('scroll-loaded');
                }
                a.trigger('clicked');

                if (!$('.recipe-category-selector li a').first().trigger('fake-click').length) {
                    init_scroll_load();
                }
            },
            complete: function () {
                a.removeClass('loading');
                $('[name=close-bulk-sm-menu]').trigger('force-close');
            }
        });
        return false;
    });

    // general restaurant selector 
    $('.menu-restaurant-selector a').click(function (e) {
        var a = $(this);
        $.ajax({
            url: a.attr('href'),
            beforeSend: function () {
                a.addClass('loading');
            },
            success: function (data) {
                a.removeClass('loading');
                $('.restaurant-selected-list').html(data.html || '');
                a.trigger('clicked');
            },
            complete: function () {
                a.removeClass('loading');
            }
        });
        return false;
    });

    $('body').on('shown.bs.modal', '.restaurant-choser-modal', function () {
        var $this = $(this);
        $this.find('button.yes').bindFirst('click', function (e) {
            if ($this.find('[name=_force_choice]').length && !$this.find('[type=checkbox].rest_id:checked').length) {
                e.stopPropagation();
                e.preventDefault();
                $this.find('.info-choose-restaurant').slideDown();
                e.stopImmediatePropagation();
                return false;
            }
        })
    });

    init_scroll_load();

    // i.e. restaurant chose on copy
    $('body').on('click change', '.list-group.labeled .list-group-item input[type=checkbox]', function () {
        var $this = $(this);
        $this.parents('.list-group-item').toggleClass('list-group-item-success', $this.is(':checked'))
    });
    $('label input[type=checkbox][data-mark-tag]').click(function () {
        var $this = $(this);
        $this.parents($this.attr('data-mark-tag')).toggleClass('marked-label', $this.is(':checked'))
    });


    // restaurant selector, depending on context
    $('body').on('click', '.chosen-restaurant-selector a', function (e) {
        var a = $(this);

        $.ajax({
            url: a.attr('href'),
            type: 'get',
            beforeSend: function () {
                a.addClass('loading');
            },
            success: function (data) {
                a.removeClass('loading');
                //var pa = $('.print-area-view');

                if (data && 'logo' in data) {
                    var pa = $('.company');
                    pa.find('.company-logo img').attr('src', data.logo);
                    pa.find('.company-name').text(data.name);
                    pa.find('.company-details span').text(data.address);
                }
                a.trigger('clicked');
            },
            complete: function () {
                a.removeClass('loading');
            }
        });

        return false;
    });

    $('.topfloating').each(function () {
        var nav = $(this), navHomeY = nav.offset().top, isFixed = false, $w = $(window);
        $w.scroll(function () {
            var scrollTop = $w.scrollTop();
            var shouldBeFixed = scrollTop > navHomeY;
            if (shouldBeFixed && !isFixed && !nav.parent().hasClass('height-transition-hidden')) {
                nav.css({
                    position: 'fixed',
                    top: 0,
                    left: nav.offset().left,
                    width: nav.width()
                });
                isFixed = true;
            } else if (!shouldBeFixed && isFixed) {
                nav.css({ position: 'static' });
                isFixed = false;
            }
        });
    });

    $('.videohelp-open[data-url], .videohelp-open[data-url-de], .videohelp-open[data-url-en]').click(function () {
        var vh = $('.videohelp-wrapper'), video = vh.find('.video'),
            lang = $('html').attr('lang'), btn = $(this),
            url = btn.attr('data-url-' + lang) || btn.attr('data-url-en') || btn.attr('data-url-de'),
            current_url = video.attr('src').replace(/\?enablejsapi=1&autoplay=1&cc_load_policy=1&cc_lang_pref=[a-z][a-z]/, '');
        let iframe = vh.find('iframe').get(0).contentWindow;

        var hidden = vh.hasClass('height-transition-hidden');
        if (current_url != url) {
            video.attr('src', url + '?enablejsapi=1&autoplay=1&cc_load_policy=1&cc_lang_pref=' + lang);
            if (!hidden) {
                return false;
            }
        }
        if (hidden) {
            vh.slideDownTransition(function () {
                if (current_url == url) {
                    setTimeout(function () {
                        iframe.postMessage('{"event":"command","func":"playVideo","args":""}', '*');
                    }, 500);
                }
            });
        } else {
            iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
            vh.slideUpTransition();
        }
    });
    $('.videohelp-wrapper .close').click(function () {
        var vh = $(this).closest('.videohelp-wrapper');
        var iframe = vh.find('iframe').get(0).contentWindow;
        iframe.postMessage('{"event":"command","func":"pauseVideo","args":""}', '*');
        vh.slideUpTransition();
    });
    /*
    $('#calendar').fullCalendar({
        header: false,
        lang: 'de',
        events: [
            {
                title  : 'event1',
                start  : '2015-04-12'
            },
            {
                title  : 'event2',
                start  : '2015-04-05',
                end    : '2015-04-07'
            },
            {
                title  : 'event3',
                start  : '2015-04-19T12:30:00',
                end  : '2015-04-19T13:30:00',
                allDay : false // will make the time show
            }
        ] 
    });
    */
    // datepicker
    (function () {
        var datepicker = $('.datepicker');
        if (datepicker.length) {
            var utcDate = function (year, month, day) {
                return new Date(Date.UTC(year, month, day));
            };
            var dateToUTC = function (date) {
                return utcDate(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
                return new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
                return new Date(date.getFullYear(), date.getMonth(), date.getDate(), date.getHours(), date.getMinutes(), date.getSeconds());
                return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), date.getUTCHours(), date.getUTCMinutes(), date.getUTCSeconds());
            };
            var parseDates = function (str, format) {
                return $.map($.grep($.isArray(str) ? str : str.split(","), function (t) {
                    return t && t.length > 0;
                }), function (n, i) {
                    var d = dateToUTC(new Date(n));
                    if (!format) {
                        return d / 1000
                    } else
                        if (format == 'object') {
                            return d;
                        }
                });
            };


            var selectDay = function (date, picker) {

                var date = dateToUTC(new Date(date)), s = date / 1000, day = date.getDate(),
                    odates = picker.data('original-dates') || [],
                    addDays = picker.data('add-days') || [],
                    removeDays = picker.data('remove-days') || [],
                    selected = picker.find('.ui-datepicker-calendar tbody td.ui-state-highlight[data-year=' + date.getFullYear() + '][data-month=' + date.getMonth() + '] a:contains("' + day + '")')
                        .filter(function () { return $(this).text() == day }).length,
                    addIndex = $.inArray(s, addDays),
                    removeIndex = $.inArray(s, removeDays);

                if (selected) {
                    if (addIndex < 0 && !inArray(s, odates)) {
                        addDays.push(s);
                    }
                    if (removeIndex >= 0) {
                        removeDays.splice(removeIndex, 1);
                    }
                } else {
                    if (addIndex >= 0) {
                        addDays.splice(addIndex, 1);
                    }
                    if (removeIndex < 0 && inArray(s, odates)) {
                        removeDays.push(s);
                    }
                }

                picker.data('add-days', addDays);
                picker.data('remove-days', removeDays);
            };

            var onMonthChange = function (year, month) {
                var picker = $(this), now = new Date(), url = picker.attr('data-url');

                if (url) {
                    $.ajax({
                        url: url,
                        data: {
                            m: month || now.getMonth() + 1,
                            y: year || now.getFullYear(),
                            v: visible_months - 1
                        },
                        success: function (data) {
                            if (data && data.dates) {
                                var dates = parseDates(data.dates, 'object'),
                                    removeDays = picker.data('remove-days') || [],
                                    odates = picker.data('original-dates') || [],
                                    ndates = [];

                                for (var i in dates) {
                                    var date = dates[i];
                                    var s = date / 1000;
                                    if ($.inArray(date, odates) < 0 && $.inArray(s, removeDays) < 0) {
                                        odates.push(s);
                                        ndates.push(date);
                                    }
                                }
                                picker.data('original-dates', odates);
                                picker.multiDatesPicker('addDates', ndates);
                            }
                        },
                    });
                }
            };

            var visible_months = $('.smartphone-menu:visible').length > 0 ? 1 : 3,
                config = {
                    numberOfMonths: visible_months,
                    defaultDate: +new Date(),
                    showWeek: true,
                };

            datepicker.each(function () {
                var picker = $(this);

                if (picker.closest('.print').length) {
                    return false;
                }

                if (picker.hasClass('multidate')) {
                    config.onSelect = selectDay;
                    config.onChangeMonthYear = onMonthChange;
                    config.dateFormat = 'yy.mm.dd';
                    config.onSelect = function (date) {
                        selectDay(date, picker);
                    };
                    config.beforeShow = function (a, b, c) {
                        console.log(a, b, c)
                    };

                    picker.multiDatesPicker(config);
                    onMonthChange.call(picker);

                } else {
                    var seldate = picker.attr('data-date');
                    if (seldate) {
                        config.setDate = new Date(seldate);
                    }
                    var maxdate = picker.attr('data-max-date');
                    if (maxdate) {
                        config.maxDate = new Date(maxdate);
                    }
                    picker.datepicker(config);
                }
            });

            // week or weekday click on multidatepicker
            $('body').on('click', '.datepicker.multidate .ui-datepicker thead th, .datepicker.multidate tbody .ui-datepicker-week-col', function () {
                var $this = $(this), el = $this.get(0), daysAdd = [], daysRemove = [];

                if ($this.hasClass('ui-datepicker-week-col')) {
                    $this.siblings('td:not(.ui-datepicker-unselectable)').each(function () {
                        var $this = $(this), date = utcDate($this.attr('data-year'), $this.attr('data-month'), $this.text());
                        $this.hasClass('ui-state-highlight') ? daysRemove.push(date) : daysAdd.push(date);
                    });
                } else {
                    wday = Array.prototype.indexOf.call(el.parentNode.children, el);

                    $this.closest('.ui-datepicker-calendar').find('tbody td').filter(function (idx) {
                        var $this = $(this), td = $this.get(0);
                        return (Array.prototype.indexOf.call(td.parentNode.children, td) == wday &&
                            !$this.hasClass('ui-datepicker-unselectable')
                        )
                    }).each(function () {
                        var $this = $(this), date = utcDate($this.attr('data-year'), $this.attr('data-month'), $this.text());
                        $this.hasClass('ui-state-highlight') ? daysRemove.push(date) : daysAdd.push(date);
                    });
                }

                var calender = $this.closest('.datepicker');
                if (daysAdd.length) {
                    calender.multiDatesPicker(
                        'addDates', daysAdd
                    );
                    $.each(daysAdd, function () {
                        selectDay(this, calender);
                    });
                }
                if (daysRemove.length) {
                    calender.multiDatesPicker(
                        'removeDates', daysRemove
                    );
                    $.each(daysRemove, function () {
                        selectDay(this, calender);
                    });
                }
            });
            // month click
            $('body').on('click', '.datepicker.multidate .ui-datepicker-title', function () {
                var $this = $(this), calender = $this.closest('.datepicker'), days = [];
                $this.closest('.ui-datepicker-group').find('.ui-datepicker-calendar tbody td:not(.ui-datepicker-unselectable,.ui-datepicker-week-col)').each(function () {
                    var $this = $(this), date = utcDate($this.attr('data-year'), $this.attr('data-month'), $this.text());
                    days.push(date)
                });

                var name = 'last-action-' + $this.text(), last_action = calender.data(name);
                var action = (!last_action || last_action == 'removeDates') ? 'addDates' : 'removeDates';
                calender.data(name, action);

                if (days.length) {
                    calender.multiDatesPicker(
                        action, days
                    );
                    $.each(days, function () {
                        selectDay(this, calender);
                    });
                }
            })
        }
    })();

    $.fn.toggleSlide = function () {
        return $(this).each(function () {
            var $this = $(this);
            if ($this.is(':visible')) {
                $this.slideUp();
            } else {
                $this.slideDown();
            }
        });
    };

    // ingredient inbox messages
    var recalc_messages = function () {
        var unread_msgs = $('.unread-messages');
        setTimeout(function () {
            var unread = $('.messages .open-message.status-u').length;
            if (unread) {
                unread_msgs.removeClass('read-messages zero').text(unread);
            } else {
                var read = $('.messages .open-message.status-r:not(.accepted-message)').length;
                unread_msgs.addClass('read-messages').text(read);
                if (read == 0) {
                    unread_msgs.addClass('zero').text(read);
                }
            }
            unread_msgs.show();
        }, 200);
    };
    $('.open-message').click(function () {
        var $this = $(this), div = $this.next('.message');

        if (div.length) {
            if (div.is(':visible')) {
                div.trigger('close');
            } else {
                div.trigger('open');
            }
        } else {
            $.ajax({
                url: $this.attr('href'),
                type: 'get',
                beforeSend: function () {
                    $('html').addClass('async-loading');
                },
                success: function (data) {
                    if (data.html) {
                        $this.siblings().removeClass('list-group-item-success');

                        div = $(data.html);
                        div.slideUp();
                        $this.after(div);
                        div.trigger('open');
                    }
                }
            });
        }

        return false;
    });
    $('body').on('open', '.messages .message', function () {
        var div = $(this), unread = div.prev('.open-message').hasClass('status-u');
        div.siblings().removeClass('list-group-item-success').filter('.message').slideUp();

        div.slideDown().prev('.open-message').removeClass('status-u status-r').addClass('status-r list-group-item-success');
        recalc_messages();
    });
    $('body').on('close', '.messages .message', function () {
        $(this).slideUp().prev('.open-message').removeClass('list-group-item-success');
        recalc_messages();
    });
    $('body').on('click', '.message .accept-message', function () {
        var $this = $(this);
        $(this).async(function () {
            $this.closest('.message').find('.message-accepted').slideDown()
                .closest('.message').prev('.open-message').removeClass('status-u status-r').addClass('status-r accepted-message');
            $this.closest('.row').remove();
            recalc_messages();
        });
        return false;
    });
    $('body').on('click', '.message .unread-message', function () {
        var $this = $(this);
        $(this).async(function () {
            $this.closest('.message').trigger('close').prev('.open-message').removeClass('status-u status-r').addClass('status-u');
        });
        return false;
    });
    $('body').on('click', '.message .hide-message', function () {
        var $this = $(this);
        $(this).async(function () {
            $this.closest('.message').trigger('hide');
        });
        return false;
    });
    $('body').on('hide', '.messages .message', function () {
        var msg = $(this);
        msg.slideUp(function () {
            msg.prev('.open-message').removeClass('list-group-item-success').slideUp(function () {
                $(this).remove();
                recalc_messages();
            });
        });
    });

    // helper
    Handlebars.registerHelper('toCssClass', function (str) {
        return str.toLowerCase().replace(/[^A-Za-z0-9]/g, '-');
    });

    // resize-content
    $('.resize-full, .resize-small').on('click', function () {
        $('body').toggleClass('fs');
    });
    // dmenu
    (function () {
        var dmenu = $('.dmenu:not(.view)');
        if (dmenu.length) {
            var form = dmenu.form();

            var add_remove = $(
                '<div class="add-remove">' +
                '<i class="glyphicon glyphicon-plus-sign add"></i> ' +
                '<i class="glyphicon glyphicon-minus-sign remove"></i>' +
                '</div>'
            );
            $('.dmenu .parts > *').append(add_remove);

            if (navigator.userAgent.toLowerCase().match(/firefox/)) {
                var m = navigator.userAgent.toLowerCase().match(/rv:\s*(2[0-9])/);
                if (m) {
                    //$('html').addClass('firefox'+m[1]);
                    $('html').addClass('firefox2x');
                }
            }

            // add row
            $('.dmenu').on('click', '.row-name .add-remove .add', function () {
                var p = $(this).closest('.parts');
                p.after(p.clone());
            });
            // remove row
            $('.dmenu').on('click', '.row-name .add-remove .remove', function () {
                $(this).closest('.parts').on('webkitTransitionEnd otransitionend oTransitionEnd msTransitionEnd transitionend', function () {
                    $(this).remove()
                }).addClass('closed');
            });
            // change row name
            $('.dmenu').on('click', '.name', function () {
                var name = $(this);
                modal_input(dmenu.attr('data-text-row-name'), function (data) {
                    if (!$.trim(data).length) {
                        return false;
                    }
                    name.text(data);
                    $(this).modal('hide');
                }, null, name.text());
            });
            // add recipe 
            $('.dmenu').on('click', '.part .add', function () {
                dmenu.find('.sel-add').removeClass('.sel-add');
                $(this).addClass('sel-add');
                $('<a>').attr('href', dmenu.attr('data-add-recipe-url')).async();
            });
            // clean up on modal close 
            $('body').on('hide.bs.modal', '.recipe-menu-add-recipe-modal', function () {
                dmenu.find('.sel-add').removeClass('sel-add');
            });
            // add recipe from the list
            $('body').on('click', '.recipe-menu-add-recipe-modal li[data-id]', function () {
                var $this = $(this), id = $this.attr('data-id'), modal = $('.recipe-menu-add-recipe-modal');
                var day = dmenu.find('.sel-add').closest('.part');
                if (day.length) {
                    var meal = $('<span class="meal"></span>').attr('data-id', id).text($this.text());
                    day.append(meal);
                }
                modal.modal('hide');
            });
            // remove recipe 
            $('.dmenu').on('click', '.meal', function () {
                var meal = $(this);
                modal_confirm(dmenu.attr('data-text-remove-recipe'), function (data) {
                    meal.slideUp(function () {
                        meal.remove();
                    });
                    $(this).modal('hide');
                });
            });
            // meals sortable
            dmenu.sortable({
                items: '.meal',
                axis: 'y',
                containment: 'parent',
                cursor: 'move'
            });
            // rows sortable
            /*
            dmenu.find('.parts').sortable({ 
                handle: '.row-name, .row-name .name',
                axis: 'y',
                cursor: 'move',
                containment: 'parent',
                start: function(e, ui) {
                    ui.item.css('display', 'table');
                },
                stop: function(e, ui) {
                    ui.item.css('display', 'table-row');
                },
            });
            */
            // datepickers
            form.find('[name=date_from]').datepicker({
                dateFormat: 'dd.mm.yy',
                defaultDate: +new Date(),
                numberOfMonths: 3,
                showWeek: true,
                onClose: function (selectedDate, dp) {
                    var date = $.datepicker.parseDate($(this).datepicker('option', 'dateFormat'), selectedDate);
                    date.setDate(date.getDate() + 7);

                    form.find('[name=date_until]')
                        .datepicker("option", "minDate", selectedDate)
                        .datepicker("option", "defaultDate", date)
                        .datepicker('show')
                },
            });
            form.find('[name=date_until]').datepicker({
                defaultDate: '+1w',
                numberOfMonths: 3,
                showWeek: true
                //onClose: function(selectedDate, dp) {
                //    form.find('[name=date_from]').datepicker( "option", "maxDate", selectedDate )
                //},
            });

            dmenu.find('.day').on('click', function () {
                var $this = $(this), m = $this.attr('class').match(/(monday|tuesday|wednesday|thursday|friday|saturday|sunday)/);

                if (m) {
                    var ps = dmenu.find('.' + m[1]);
                    if (!$this.hasClass('day-off') && !$this.hasClass('day-hidden')) {
                        ps.removeClass('day-off day-hidden').addClass('day-off');

                    } else
                        if ($this.hasClass('day-off')) {
                            ps.removeClass('day-off day-hidden').addClass('day-hidden');
                        } else {
                            ps.removeClass('day-off day-hidden');
                        }
                }
            });

            // submit
            form.find('.save').click(function () {
                var data = {
                    date_from: form.find('[name=date_from]').val(),
                    date_until: form.find('[name=date_until]').val(),
                    time_from: form.find('[name=time_from]').val(),
                    time_until: form.find('[name=time_until]').val(),
                    title: form.find('[name=title]').val(),
                    parts: [],
                    restaurant: [],
                    days_off: [],
                    days_hidden: []
                };

                if (form.find('[name=id]').length) {
                    data.id = form.find('[name=id]').val()
                }

                form.find('[name="restaurant[]"][type=checkbox]:checked,[name="restaurant[]"][type=hidden]').each(function () {
                    data.restaurant.push($(this).val());
                });
                form.find('.day.day-off').each(function () {
                    data.days_off.push($(this).attr('data-day-id'))
                });
                form.find('.day.day-hidden').each(function () {
                    data.days_hidden.push($(this).attr('data-day-id'))
                });

                dmenu.find('.parts').each(function () {
                    var $this = $(this), part = {};
                    part.name = $this.find('.row-name .name').text();
                    part.days = {};

                    $this.find('.part').each(function (i) {
                        var $this = $(this), day_id = $this.attr('data-day-id');
                        part.days[day_id] = [];

                        $(this).find('.meal').each(function () {
                            var meal = $(this);

                            part.days[day_id].push(
                                meal.attr('data-id') * 1
                            );
                        });
                    });

                    data.parts.push(part);
                });

                $.ajax({
                    url: form.attr('action'),
                    type: 'post',
                    data: data
                })

            });
        }
        var dmenu_view = $('.dmenu.view');
        if (dmenu_view.length) {
            // menu create conf
            $('.recipe-menu-conf-selector input').on('click clicked', function (e) {
                var $this = $(this),
                    type = $this.attr('name'),
                    show = $this.is(':checked'),
                    smenu = $('.recipe-menu-conf-selector');

                dmenu_view.toggleClass('f-' + type, show);
                if (e.type != 'click') {
                    return
                }
                $.ajax({
                    url: smenu.attr('data-url'),
                    method: 'post',
                    data: { k: type, v: show }
                });
            }).trigger('clicked');
        }
    })();



    $('.carousel').bcSwipe({ threshold: 50 });

    // end of ready
});
/*
 * lists 
 */
$(document).ready(function () {
    $('.quantities-open').click(function () {
        $(this).trigger('toggle-label');
        $('.ingredients .ing-row').toggleClass('hide-quantities');
    });
    // toggle selected
    $('.row .nice-select input:not([data-url])').on('change', function () {
        var $this = $(this);
        $this.closest('.ing-row, .ing').toggleClass('selected', $this.is(':checked'));
    });
    $('body').on('click focus keydown', '.ing-row.selected > * > *:not(.ingredient-select-div) *', function (e) {
        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
        $(this).blur();
        return false;
    });

    // toggle class open/closed
    $('body').on('show.div.slide', '.bubble-popover .add-to-list-popover #new-list', function () {
        $(this).closest('.bubble-popover').addClass('new-list-open');
    });
    $('body').on('hide.div.slide', '.bubble-popover .add-to-list-popover #new-list', function () {
        $(this).closest('.bubble-popover').removeClass('new-list-open');
    });
    // focus on show
    $('body').on('shown.div.slide', '.bubble-popover .add-to-list-popover #new-list', function () {
        $(this).find('input').select().closest('.bubble-popover').addClass('new-list-open');
    });
    //

    // update on quantity change 
    $('body').on('status-updated', 'input.ingredient-selected[data-url]', function (e, data) {
        var $this = $(this), row = $this.closest('.row'), label = $this.closest('.nice-select');

        if (data && data.selected == 1) {
            row.addClass('selected');
            label.addClass('selected');
        } else {
            row.removeClass('selected');
            label.removeClass('selected');
        }
    });

    // disable event if popover is hidden
    $('body').on('hidden.bs.popover', '.add-to-list', function () {
        $('html').off('click keyup', '*:not(.add-to-list)');
    });
    // fast add to list
    $('body').on('click', '.add-to-list', function () {
        var $this = $(this), id = $this.closest('.ing-row, .row.ing').attr('data-id');

        if (!id) {
            return false;
        }

        var pid = $this.attr('aria-describedby');
        if (pid && $('#' + pid).length) {
            $this.popover('destroy');
            return false;
        }

        $('.add-to-list').popover('destroy');
        $this.async(function (data) {
            var p = $this.offset();

            $this.popover({
                trigger: 'manual',
                container: 'body',
                template: $('#bubble-popover').html(),
                html: true,
                title: '',
                content: data.html,
                viewport: $('body'),
                placement: function (context, source) {
                    var position = $(source).position();
                    var right = position.left + 280;
                    var html_width = $('html').width();

                    if (right < html_width) {
                        return 'right';
                    }
                    return 'auto';
                }
            }).popover('show');

            // close popovers on any click outside or escape key
            $('html').on('click keyup', '*:not(.add-to-list)', function (e) {
                if (e.type != 'keyup' || e.which == 27) {
                    $('.add-to-list').popover('destroy');
                }
                console.log('esc');
            });

        });

        return false;
    });
    // close
    $('body').on('click', '.add-to-list-popover .close', function () {
        $(this).closest('.popover').popover('destroy');
    });
    // de/activate list
    $('body').on('click', '.add-to-list-popover li[data-href]', function (e) {
        var $this = $(this);

        $this.async(function (data) {
            if (data && 'n' in data) {
                if (data.n == 1) {
                    $this.addClass('active');
                } else {
                    $this.removeClass('active');
                }
            }
        });
        return false;
    });
    // disable hide on click in the popover
    $('body').on('click', '.add-to-list-popover', function (e) {
        if ('target' in e) {
            var target = $(e.target);
            if (target.attr('type') == 'submit') {
                target.closest('form').submit();
            }
        }

        e.stopPropagation();
        e.preventDefault();
        e.stopImmediatePropagation();
        return false;
    });

    // save new list
    $('body').on('submit', '.add-to-list-popover form', function (e) {
        var $this = $(this);

        $this.save(function (data) {
            var li = $(Handlebars.compile($('#add-list-tmpl').html())(data));
            var popover = $this.closest('.add-to-list-popover');
            popover.find('ul').append(li);
            var body = popover.find('.po-body').get(0);
            body.scrollTop = Math.max(body.scrollHeight, body.clientHeight) - body.clientHeight;

            $this.fadeOut(function () {
                var msg = popover.find('#list-created').fadeIn();
                setTimeout(function () {
                    msg.fadeOut();
                    popover.find('#new-list').slideUp(function () {
                        $this.show();
                    });
                }, 2000);
            });
        });
        return false;
    });
});
// ready2order settings
$(document).ready(function () {
    $(".onlyone_checkbox").change(function () {
        var checked = $(this).is(':checked');
        $(".onlyone_checkbox").prop('checked', false);
        if (checked) {
            $(this).prop('checked', true);
        }
    });
});
